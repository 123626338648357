import { LogLevel } from '@hcr/utils'

import { LocaleId } from '../models'

import type { EnvironmentVariables } from './types'

export const Environment: EnvironmentVariables = {
  API: {
    Auth: {
      clientId: 'ed9a36a5-56d9-460a-8631-8d000db5a180',
      domain: 'holidayclubcustomerb2c.b2clogin.com',
      redirectUrl: 'https://test.loma.holidayclubresorts.com',
      url: 'https://holidayclubcustomerb2c.b2clogin.com/holidayclubcustomerb2c.onmicrosoft.com/B2C_1_pwa_signin',
    },
    Consumer: {
      url: 'https://test-hcdpcommon-apim.azure-api.net/v1',
      useMocks: false,
    },
    Optimizely: {
      url: 'https://testing.holidayclubresorts.com/api/v1/mobile',
      useMocks: false,
    },
    Owners: {
      url: 'https://osakaspalvelu-test.holidayclubresorts.com',
    },
    Webshop: {
      url: 'https://testing.holidayclubresorts.com',
    },
  },
  Analytics: {
    containerId: 'GTM-5T6NGL3',
    useAnalytics: true,
  },
  General: {
    localeId: LocaleId.Fi,
    logLevel: LogLevel.Log,
    name: 'test',
  },
  Gomeddo: {
    business: 'hcr-qa2',
    page: '2b35c3ec-3b95-40d6-a3e3-e998ace598ab',
  },
  Sentry: {
    dsn: 'https://64cb63b46f76be28ddb8e7f1a40a42f8@o4505834828660736.ingest.sentry.io/4505838048313344',
    tracesSampleRate: 1.0,
    useSentry: true,
  },
}
