import Cookies from 'js-cookie'
import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAnalytics } from 'use-analytics'

import { isNotUndefined, isUndefined } from '@hcr/utils'

import { ANALYTICS_CONFIG } from '../configs'
import type { ConsentSettings } from '../models'
import { Consent } from '../models'
import { createConsentSettingsChangedEvent } from '../utils'

interface ConsentSettingsContext {
  close: () => void
  data: ConsentSettings
  isOpen: boolean
  open: () => void
  store: (settings: ConsentSettings) => Promise<void>
}

const DEFAULT_CONSENT_SETTINGS_CONTEXT: ConsentSettingsContext = {
  close: () => void null,
  data: {
    [Consent.Advertising]: false,
    [Consent.Performance]: false,
    [Consent.Targeting]: false,
  },
  isOpen: false,
  open: () => void null,
  store: () => Promise.resolve(),
}

const ConsentSettingsContext = createContext<ConsentSettingsContext>(DEFAULT_CONSENT_SETTINGS_CONTEXT)

type ConsentSettingsProviderProps = PropsWithChildren

// Note: The consent settings string is encoded according to the "CookieConsent" cookie in the Webshop:
//  1 Strictly necessary cookies (required)
//  2 Functional cookies (required)
//  3 Analytics & performance cookies (optional)
//  4 Targeting cookies (optional)
//  5 Advertising cookies (optional)
export const ConsentSettingsProvider: FC<ConsentSettingsProviderProps> = ({ children }) => {
  const analytics = useAnalytics()
  const [settings, setSettings] = useState<ConsentSettings>(DEFAULT_CONSENT_SETTINGS_CONTEXT.data)
  const [isOpen, setIsOpen] = useState(DEFAULT_CONSENT_SETTINGS_CONTEXT.isOpen)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const load = (): void => {
    const settingsEncoded = Cookies.get(ANALYTICS_CONFIG.consent.cookieName)
    if (isNotUndefined(settingsEncoded)) {
      const settings: ConsentSettings = {
        [Consent.Performance]: settingsEncoded[2] === '3',
        [Consent.Targeting]: settingsEncoded[3] === '4',
        [Consent.Advertising]: settingsEncoded[4] === '5',
      }
      setSettings(settings)
    }
  }

  const store = (settings: ConsentSettings): Promise<void> => {
    const settingsEncoded = [
      '1',
      '2',
      settings[Consent.Performance] ? '3' : '0',
      settings[Consent.Targeting] ? '4' : '0',
      settings[Consent.Advertising] ? '5' : '0',
    ].join('')

    Cookies.set(ANALYTICS_CONFIG.consent.cookieName, settingsEncoded, {
      expires: ANALYTICS_CONFIG.consent.expirationTime,
      sameSite: 'none',
      secure: true,
    })
    setSettings(settings)
    return analytics.track(...createConsentSettingsChangedEvent({ settingsEncoded }))
  }

  useEffect(() => {
    if (isUndefined(Cookies.get(ANALYTICS_CONFIG.consent.cookieName))) {
      open()
    } else {
      load()
    }
  }, [])

  return (
    <ConsentSettingsContext.Provider
      value={{
        close,
        data: settings,
        isOpen,
        open,
        store,
      }}
    >
      {children}
    </ConsentSettingsContext.Provider>
  )
}

export const useConsentSettings = (): ConsentSettingsContext => {
  const context = useContext(ConsentSettingsContext)

  if (isUndefined(context)) {
    throw new Error('useConsentSettings must be used within ConsentSettingsProvider')
  }

  return context
}
